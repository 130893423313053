<template>
	<div>
		<div>
			<transition name="drop">
				<div class="pg_login">
					<div class="pg_login_outer_container">
						<div class="pg_login_container">
							<div class="pg_login_logo">
								<span class="svg-container">
									<svg role="img">
										<use :xlink:href="'#pg_logo'"></use>
									</svg>
								</span>
							</div>
							<form class="pg_login_form" @submit.prevent="submit">
								<input
									v-model="username"
									type="text"
									placeholder="Gebruikersnaam"
									data-form="username"
								/>
								<input
									v-model="password"
									type="password"
									placeholder="Wachtwoord"
									data-form="password"
								/>

								<button
									v-show="!loading"
									class="button v_ghost_brand_primary v_has-icon-right v_smaller pg_login_form_button"
									@click.prevent="submit"
								>
									<span class="button-txt">Inloggen</span>
									<sb-icon :icon-id="'icon_arrow-right'"></sb-icon>
								</button>

								<sb-loader :loading="loading" class="pg_login_form_loader" size="36"></sb-loader>

								<div class="pg_login_form_error error">{{ error }}</div>
							</form>
						</div>
					</div>
				</div>
			</transition>

			<transition name="fade">
				<div class="pg_login_bg"></div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import SbIcon from "../elements/Icon.vue";
import SbLoader from "../elements/Loader.vue";

export default {
	name: "Login",
	components: {
		SbLoader,
		SbIcon,
	},
	data() {
		return {
			error: "",
			loading: false,
			username: "",
			password: "",
		};
	},
	mounted() {
		this.closeOverlay();
	},
	methods: {
		...mapActions("overlay", ["closeOverlay"]),
		...mapActions("auth", {
			authorizeClub: "authorize",
			isLoggedIn: "info",
		}),
		async submit() {
			this.loading = true;
			this.error = "";
			try {
				const data = await this.authorizeClub({
					username: this.username,
					password: this.password,
					grant_type: "password",
					admin: true,
				});

				if (data) {
					this.$router.push("/");
				} else {
					throw "verkeerde gebruikersnaam / wachtwoord";
				}
			} catch (error) {
				this.error = err;
			} finally {
				await this.isLoggedIn();
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
////
////    GENETRICS
////
.pg_login {
	position: fixed;
	z-index: 5556;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: $default-padding * 2;

	&_form {
		position: relative;
		width: 182px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&_error {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%) translateY(calc(100% + #{$default-padding}));
			width: 200%;
			text-align: center;
		}

		&_button {
			height: 52px;
			margin: 0;
		}

		&_loader {
			padding: 8px;
			margin: 0;
		}
	}
}
.pg_login_outer_container {
	$login-size: 600px;

	width: $login-size;
	height: $login-size;
	margin: 0 auto;
	padding: $default-padding * 6;
	display: flex;
	justify-content: center;
	align-items: center;

	&:after {
		content: "";
		z-index: -1;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		position: absolute;
		width: $login-size;
		height: $login-size;
		border-radius: calc($login-size / 2);
		background: $brand-white;
		box-shadow: 0 0 30px rgba(black, 0.3);
	}
}
.pg_login_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.pg_login_logo {
	.svg-container {
		width: 160px;
	}
	svg {
		width: 160px;
		fill: $brand-primary;
	}
}

////
////    BG
////
.pg_login_bg {
	position: fixed;
	z-index: 5555;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($brand-primary, 1);
}

.error {
	color: red;
}

.pg_loader {
	position: relative;
	top: unset;
	left: unset;
	width: unset;
}
</style>
